/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         25/02/24 - 21:30
 * Module name:  mkad-preference-default.constant
 * File name:    mkad-preference-default.constant.ts
 * IDE:          WebStorm
 */


import {THOUSAND} from '@shared/constants';

/**
 * @description
 * Variables with default system configurations
 * Default number of decimal places
 */
export const SETTING_DECIMAL = 0;

/**
 * @description
 * Default export format
 */
export const SETTING_FORMAT = 0;

/**
 * @description
 * Default null handling option
 */
export const SETTING_MANAGE_NULL = 0;


/**
 * @description
 * Default duration in toast messages
 */
export const SETTING_TOAST_TIMEOUT = 5;


/**
 * @description
 * Default duration in toast messages
 */
export const SETTING_TIMEOUT = SETTING_TOAST_TIMEOUT * THOUSAND;
