/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         20/12/23 - 12:12 PM
 * Module name:  base.service.ts
 * File name:    base.service.ts
 * IDE:          WebStorm
 */

import {TableBasicConfigInterface} from '@shared/components/table';

/**
 * @description
 * Configuration of tags in the table
 */
export const TABLE_CONFIG_TAG: TableBasicConfigInterface = {
  activeLabel: 'app.shared.tables.column.tag.active',
  activeCssClass: 'active',
  inactiveLabel: 'app.shared.tables.column.tag.inactive',
  inactiveCssClass: 'inactive',
};
