/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2023 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2023 - 2024. Todos los derechos reservados.
 */

/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         10/07/23 - 6:48 AM
 * Module name:  main-menu.constants
 * File name:    main-menu.constants.ts
 * IDE:          WebStorm
 */

import {NbMenuItem} from '@nebular/theme';
import {MenuComponentConfigEnum} from '../../enums';

export interface MkadMenuItem extends NbMenuItem {
  data: {
    component?: MenuComponentConfigEnum,
    id?: number,
    guest?: boolean,
    level?: number,
    cssClass?: string,
    description?: string,
  };
}

/**
 * @description
 * Array menu option
 */
export const PUBLIC_MENU_ITEMS_CONST: NbMenuItem[] = [
  {
    title: 'app.main.menu.home.title',
    home: true,
    link: '/home',
    icon: {
      icon: 'house',
      pack: 'far'
    },
    data: {
      id: 1,
      level: 6,
      guest: true
    }
  },
  {
    title: 'app.main.menu.panel.title',
    icon: {
      icon: 'grid-2',
      pack: 'far'
    },
    data: {
      id: 2,
      level: 1,
      guest: false
    }
  },
  {
    title: 'app.main.menu.reportDetail.title',
    icon: {
      icon: 'file-chart-column',
      pack: 'far'
    },
    data: {
      id: 3,
      level: 3,
      guest: false
    }
  },
  {
    title: 'app.main.menu.reports.title',
    icon: {
      icon: 'file-chart-column',
      pack: 'far'
    },
    data: {
      id: 4,
      level: 4,
      guest: false
    }
  },
  {
    title: 'app.main.menu.settings.title',
    link: '/settings',
    icon: {
      icon: 'gear',
      pack: 'far'
    },
    data: {
      id: 5,
      level: 5,
      guest: false
    }
  },
  {
    title: 'app.main.menu.mkad.title',
    group: true,
    data: {
      level: 6,
      guest: true
    }
  },
  {
    title: 'app.main.menu.about.title',
    icon: {
      icon: 'circle-exclamation',
      pack: 'far'
    },
    data: {
      component: MenuComponentConfigEnum.MkadAboutUsComponent,
      id: 6,
      level: 6,
      guest: true,
    }
  },
  {
    title: 'app.main.menu.session.title',
    group: true,
    data: {
      level: 5,
      guest: false,
    }
  },
  {
    title: 'app.main.menu.logout.title',
    icon: {
      icon: 'right-from-bracket',
      pack: 'far'
    },
    data: {
      component: MenuComponentConfigEnum.MkadLogoutComponent,
      id: 7,
      guest: false,
      level: 5
    }
  }
];

export const MENU_MAIN_CONST: NbMenuItem[] = [...PUBLIC_MENU_ITEMS_CONST];
