/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
* Project:      frontend
* Developed by: Jhoana Marcela Rodriguez Fernandez
* Date:         6/2/2024 - 12:14
* Module name:  frontend
* File name:    message-timeout.constants.ts
* IDE:          WebStorm
*/

/**
 * @description
 * List of duration options for toas-type messages
 */
export const MESSAGE_TIMEOUT_OPTION: number[] = [5, 10, 15, 20, 25, 30];
