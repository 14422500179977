/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         22/12/23 - 1:22 AM
 * Module name:  pantone-major.constants
 * File name:    pantone-major.constants.ts
 * IDE:          WebStorm
 */

/**
 * @description
 * Type of the object defining the base colours for the bar chart
 */
export interface PantoneBaseInterface {
  comm: string,
  danger: string,
  warning: string,
  alert: string,
  success: string
}

/**
 * @description
 * Object with the base colours for the status graphic
 */
export const PantoneBaseConst: PantoneBaseInterface = {
  comm: '#281642',
  danger: '#ff3d71',
  warning: '#e19600',
  alert: '#decf00',
  success: '#00d68f'
};
