/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         11/03/24 - 19:58
 * Module name:  extra-icon-config.constant
 * File name:    extra-icon-config.constant.ts
 * IDE:          WebStorm
 */
import {IconOptions, PointTuple} from 'leaflet';

const VALUE_41 = 41;
const VALUE_12 = 12;
const VALUE_34 = 34;
const VALUE_16 = 16;
const VALUE_28 = 28;
/**
 * @description
 * Size of the icon of leaflet map
 */
const ICON_SIZE = [25, VALUE_41]; // PointTuple type
/**
 * @description
 * Path for the x2 svg icon
 */
const ICON_RETINA_URL_ = 'assets/image/leaflet/marker-icon-2x.png';

/**
 * @description
 * Path for the svg icon
 */
const ICON_URL_ = 'assets/image/leaflet/marker-icon.png';

/**
 * @description
 * Path for the svg shadow icon
 */
const SHADOW_URL_ = 'assets/image/leaflet/marker-shadow.png';

/**
 * @description
 * Settings for the markers icons on leaflet maps
 */
export const EXTRA_ICON_CONFIG: IconOptions = {
  iconRetinaUrl: ICON_RETINA_URL_,
  iconUrl: ICON_URL_,
  shadowUrl: SHADOW_URL_,
  iconSize: <PointTuple>ICON_SIZE,
  iconAnchor: [VALUE_12, VALUE_41],
  popupAnchor: [1, VALUE_34],
  tooltipAnchor: [VALUE_16, VALUE_28],
  shadowSize: [VALUE_41, VALUE_41]
};
