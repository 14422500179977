/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2023 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2023 - 2024. Todos los derechos reservados.
 */

/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         11/07/23 - 9:38 PM
 * Module name:  terms-conditions-path.enums
 * File name:    terms-conditions-path.enums.ts
 * IDE:          WebStorm
 */

/**
 * Enumerator of terms and conditions routes
 */
export enum TermsConditionsPathEnum {
  es = 'https://meerkad.net/index.php/es/politicas/politica-de-privacidad',
  en = 'https://meerkad.net/index.php/en/policies/terms-conditions',
}
