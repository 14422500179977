/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         25/02/24 - 11:26
 * Module name:  mkad-map-config-default.constants
 * File name:    mkad-map-config-default.constants.ts
 * IDE:          WebStorm
 */


import {latLng, LatLng, LatLngBounds} from 'leaflet';

/**
 * @description
 * Default latitude in the system
 */
export const DEFAULT_MAP_LATITUDE = 2.9273;

/**
 * @description
 * Default longitude in the system
 */
export const DEFAULT_MAP_LONGITUDE = -75.28189;

/**
 * @description
 * Default location in the system
 */
export const DEFAULT_MAP_LOCATION: LatLng = latLng(DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE);

export const MAX_LAT_NORTH = 90;
export const MAX_LNG_EAST = 180;
export const MAX_LAT_SOUTH = -90;
export const MAX_LNG_WEST = -180;

/**
 * @description
 * Default bounds limit of map
 */
export const DEFAULT_MAP_BOUNDS = new LatLngBounds(new LatLng(MAX_LAT_NORTH, MAX_LNG_WEST), new LatLng(MAX_LAT_SOUTH, MAX_LNG_EAST));


/**
 * @description
 * Default zoom for system maps
 */
export const MAP_ZOOM: number = 8;

/**
 * @description
 * Default max zoom for system maps
 */
export const MAP_ZOOM_MAX: number = 20;

/**
 * @description
 * Default url copy for footer map
 */
export const MAP_ATTRIBUTION = '&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

/**
 * @description
 * cdn for the operation of the map
 */
export const MAP_URL_TEMPLATE = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

