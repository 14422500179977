/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         21/02/24 - 2:02
 * Module name:  menu-component-overlay.enums
 * File name:    menu-component-overlay.enums.ts
 * IDE:          WebStorm
 */
/**
 * Enumerator of menu component overlay
 */
export enum MenuComponentOverlayEnum {
  UserProfile,
  AppPreferences,
  ChangePassword,
  Logout
}
