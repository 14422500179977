import {Component, Input} from '@angular/core';
import {NgClass} from '@angular/common';
import {TableActionEnum, TableIconEnum, TABLE_ICONS_MAP} from '@shared/components/table';
import {NbIconModule} from '@nebular/theme';

@Component({
  selector: 'mkad-table-button',
  standalone: true,
  imports: [NgClass, NbIconModule],
  template: `
    <button class="table__btn"
            [disabled]="disabled"
            [ngClass]="{
            'update': isUpdate,
            'delete': isDelete,
            'custom-basic': isCustomBasic,
            'custom-groups': isCustomGroups,
            'custom-params': isCustomParams,
            'custom-ports': isCustomPorts,
            'create': isCreate,
            'cursor-pointer': !disabled
            }">
      <nb-icon
        [class]="'text-sm tn_grid_center icon ' + type"
        [pack]="iconsMap[actionIcon].pack ?? ''"
        [icon]="iconsMap[actionIcon].icon ?? ''"/>
    </button>
  `,
})
export class TableButtonComponent {
  @Input({required: true}) type: TableActionEnum = TableActionEnum.add;
  @Input({required: false}) disabled = false
  private readonly actionType = TableActionEnum;
  protected readonly iconsMap = TABLE_ICONS_MAP;


  get isCustomBasic(): boolean {
    return this.type === this.actionType.customBasic;
  }

  get isCustomGroups(): boolean {
    return this.type === this.actionType.customGroups;
  }

  get isCustomParams(): boolean {
    return this.type === this.actionType.customParams;
  }

  get isCustomPorts(): boolean {
    return this.type === this.actionType.customPorts;
  }

  /**
   * @description
   * Method that returns the update action
   */
  get isUpdate(): boolean {
    return this.type === this.actionType.update;
  }

  /**
   * @description
   * Method that returns the delete action
   */
  get isDelete(): boolean {
    return this.type === this.actionType.delete;
  }

  /**
   * @description
   * Method that returns the created action
   */
  get isCreate(): boolean {
    return this.type === this.actionType.add;
  }

  /**
   * @description
   * Method that returns icons by action.
   */
  get actionIcon(): TableIconEnum {
    if (this.isUpdate) return TableIconEnum.pen;
    if (this.isCreate) return TableIconEnum.add;
    if (this.isDelete) return TableIconEnum.trash;
    if (this.isCustomGroups) return TableIconEnum.groups;
    if (this.isCustomParams) return TableIconEnum.params;
    if (this.isCustomBasic) return TableIconEnum.pen;
    if (this.isCustomPorts) return TableIconEnum.ports;
    return TableIconEnum.circleClose;
  }

}
