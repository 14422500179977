/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         23/02/24 - 13:21
 * Module name:  manage-null-setting.enum
 * File name:    manage-null-setting.enum.ts
 * IDE:          WebStorm
 */
/**
 * Enumerator of manage null setting
 */
export enum ManageNullSettingEnum {
  Discard,
  Average,
  PreviousValue,
  NextValue,
}
