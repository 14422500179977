/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         7/02/24 - 13:55
 * Module name:  menu-component.enums
 * File name:    menu-component-config.enums.ts
 * IDE:          WebStorm
 */
/**
 * Enumerator of menu component config
 */
export enum MenuComponentConfigEnum {
  MkadAboutUsComponent,
  MkadLogoutComponent,
}
