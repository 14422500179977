/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         18/03/24 - 17:41
 * Module name:  check-level-type.enum
 * File name:    check-level-type.enum.ts
 * IDE:          WebStorm
 */

/**
 * @description
 * type of element to which the directive applies
 */
export enum MkadCheckLevelType {
  'div' = 'div',
  'control' = 'control'
}
