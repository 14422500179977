/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         22/02/24 - 14:35
 * Module name:  mkad-file-config.constants
 * File name:    mkad-file-config.constants.ts
 * IDE:          WebStorm
 */

/**
 * @description
 * Maximum size in bytes allowed | value 0.3 megabytes
 */
export const MAX_FILE_SIZE = 300000;

/**
 * @description
 * Maximum pixel size allowed
 */
export const MAX_FILE_WIDTH = 256;

/**
 * @description
 * Allowed image formats
 */
export const FILE_FORMAT_ALLOW: string[] = ['jpg', 'png', 'jpeg', 'webp'];

/**
 * @description
 * Enumerator with errors in the form control type file
 */
export enum fileImageProportion {
  oneToOne,
  threeToOne
}

/**
 * @description
 * Enumerator with errors in the form control type file
 */
export enum fileErrors {
  maxSize = 'MAX_FILE_SIZE',
  maxWidth = 'MAX_FILE_WIDTH',
  proportion = 'PROPORTION',
  format = 'FORMAT_ALLOW'
}
