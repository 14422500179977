@if (dataTable && dataTable.columns && dataTable.data.length > 0) {
  <ngx-datatable #tableNested
                 [ngClass]="tableTheme"
                 [rows]="dataTable.data"
                 [columnMode]="assertionColumnMode(dataTable.extra?.columnMode ?? defaultColumnConfig.columnMode)"
                 [headerHeight]="defaultConfig.header"
                 [footerHeight]="defaultConfig.footer"
                 [rowHeight]="defaultConfig.row"
                 [scrollbarV]="false"
                 [sortType]="assertionSortMode(dataTable.extra?.sortType ?? defaultColumnConfig.sortType)"
                 [limit]="limit">
    <ngx-datatable-row-detail [rowHeight]="heightDetailRow" class="tn_p_3">
      <ng-template let-row="row" ngx-datatable-row-detail-template>
        @if (dataTable.children) {
          <mkad-smart-table-child [class]="row[getChildrenField] ? 'table_child_content' : ''"
                                  [dataTable]="{
                                     data: row[getChildrenField],
                                     columns: dataTable.children.columns,
                                     extra: childrenColumnConfig
                                   }"
                                  [theme]="theme"
                                  [limit]="dataTable.children.limit ?? defaultConfig.limit"
                                  (rowClick)="rowClickChildren.emit($event)"
                                  (iconClick)="iconClickChildren.emit($event)"
          />
        }
      </ng-template>
    </ngx-datatable-row-detail>
    <ngx-datatable-column
      [width]="iconColumnWidth"
      [resizeable]="false"
      [sortable]="false"
      [draggable]="false"
      [canAutoResize]="false"
      [flexGrow]="0.8"
      [cellTemplate]="iconCollapse"
    />
    @for (columnItem of dataTable.columns; track columnItem) {
      @if (columnItem) {
        @if (validArrayType(columnItem.field)) {
          not supported
        } @else {
          <ngx-datatable-column [name]="columnItem.header"
                                [prop]="assertionString(columnItem.field)"
                                [resizeable]="columnItem.resize ?? false"
                                [cellClass]="'' + columnItem.align"
                                [flexGrow]="columnItem.grow ?? 1"
                                [sortable]="columnItem.sort ?? false">
            <ng-template let-column="column" ngx-datatable-header-template>
              <strong>{{ column.name }}</strong>
            </ng-template>
            <ng-template let-dataRow="row" ngx-datatable-cell-template>
              <mkad-smart-table-column
                [columnItem]="columnItem"
                [rowItem]="dataRow"
                [columnConfig]="columnConfig"/>
            </ng-template>
          </ngx-datatable-column>
        }
      }
    }
    @if (dataTable.extra && dataTable.extra.actions) {
      <ngx-datatable-column [name]="dataTable.extra.actions.header ?? (defaultColumnConfig.actions?.header ?? '')"
                            prop="action"
                            [resizeable]="false" [flexGrow]="1"
                            [sortable]="false"
                            headerClass="tn_py_2 tn_text_center" cellClass="tn_py_2 center">
        <ng-template ngx-datatable-header-template>
          <strong> {{ dataTable.extra.actions.header ?? defaultColumnConfig.actions?.header }}</strong>
        </ng-template>
        <ng-template let-dataItem="row" ngx-datatable-cell-template>
        <span class="flex gap-2">
            @for (action of dataTable.extra.actions.data; track action) {
              <mkad-table-button
                [type]="action.type"
                [nbTooltip]="(getTooltipData(action.type)) | translate"
                [nbTooltipPlacement]="'end'"
                (click)="rowClick.emit({type: action.type, row: dataItem})"/>
            }
          </span>
        </ng-template>
      </ngx-datatable-column>
    }
  </ngx-datatable>
  <ng-template #iconCollapse let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
    <button class="table__button__toggle" [class.expanded]="expanded"
            [nbTooltip]="'app.shared.tables.action.toggle.tooltip' | translate"
            [class.datatable-icon-right]="!expanded"
            [class.datatable-icon-down]="expanded"
            (click)="handlerExpandRow(row)">
    </button>
  </ng-template>
} @else {
  <mkad-alert [message]="dataTable?.extra?.messages?.empty ?? ('app.shared.tables.message.noChildData' | translate)"
              status="warning"/>
}

