import {Component, Input} from '@angular/core';
import {DEFAULT_EXTRA_CONFIG, TableBasicConfigInterface} from '@shared/components/table';
import {NbTagModule, NbTagAppearance, NbComponentOrCustomStatus} from '@nebular/theme';

@Component({
  selector: 'mkad-table-tag',
  standalone: true,
  imports: [NbTagModule],
  template: `
    <nb-tag size="medium" [appearance]="appearance" [status]="tagStatus" [text]="tagLabel"/>
  `,
})
export class TableTagComponent {
  @Input({required: true}) value: boolean = false;
  @Input({required: false}) appearance: NbTagAppearance = 'outline';
  @Input({required: false}) config?: TableBasicConfigInterface;

  /**
   * @description
   * Method that returns the value of the tag if it is active or inactive
   */
  get tagLabel(): string {
    const tag: TableBasicConfigInterface = <TableBasicConfigInterface>DEFAULT_EXTRA_CONFIG.tag;
    const {activeLabel, inactiveLabel} = this.config ?? tag;
    return this.value ? activeLabel : inactiveLabel;
  }

  /**
   * @description
   * Method that returns the color of the tag if it is success or basic
   */
  get tagStatus(): NbComponentOrCustomStatus {
    return this.value ? 'success' : 'basic';
  }
}
