/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         21/02/24 - 10:52
 * Module name:  mkad-theme-option.constants
 * File name:    mkad-theme-option.constants.ts
 * IDE:          WebStorm
 */

import {MkadThemeValueEnum} from '../../enums';
import {MkadResumeDataListInterface} from '@shared/interface';

/**
 * @description
 * List of themes available in the system
 */
export const THEME_OPTION: MkadResumeDataListInterface<string>[] = [
  {
    id: MkadThemeValueEnum.corporate,
    name: 'app.shared.userProfile.field.theme.list.corporate',
  },
  {
    id: MkadThemeValueEnum.dark,
    name: 'app.shared.userProfile.field.theme.list.dark',
  },
  {
    id: MkadThemeValueEnum.cosmic,
    name: 'app.shared.userProfile.field.theme.list.cosmic',
  }
];
