@if (dataTable && dataTable.columns && dataTable.data && dataTable.data.length > 0) {
  <ngx-datatable [ngClass]="{
                     'dark': isDark,
                     'bootstrap': isLight
                   }"
                 [rows]="dataTable.data"
                 [columnMode]="assertionColumnMode(dataTable.extra?.columnMode ?? defaultColumnConfig.columnMode)"
                 [footerHeight]="defaultConfig.footer"
                 [headerHeight]="defaultConfig.header"
                 [limit]="limit"
                 [rowHeight]="defaultConfig.row"
                 [scrollbarV]="false"
                 [sortType]="assertionSortMode(dataTable.extra?.sortType ?? defaultColumnConfig.sortType)">
    @for (columnItem of dataTable.columns; track columnItem) {
      @if (validArrayType(columnItem.field)) {
        not supported
      } @else {
        <ngx-datatable-column [name]="columnItem.header"
                              [prop]="assertionString(columnItem.field)"
                              [resizeable]="columnItem.resize ?? false"
                              [flexGrow]="columnItem.grow ?? 1"
                              [sortable]="columnItem.sort ?? false"
                              headerClass="tn_py_2"
                              [cellClass]="'tn_py_2 ' + columnItem.align">
          <ng-template let-column="column" ngx-datatable-header-template>
            <strong>{{ column.name }}</strong>
          </ng-template>
          <ng-template let-dataRow="row" ngx-datatable-cell-template>
            <mkad-smart-table-column
              [columnItem]="columnItem"
              [rowItem]="dataRow"
              [columnConfig]="columnConfig"/>
          </ng-template>
        </ngx-datatable-column>

      }
    }
    @if (dataTable.extra && dataTable.extra.actions) {
      <ngx-datatable-column [name]="dataTable.extra.actions.header ?? (defaultColumnConfig.actions?.header ?? '')"
                            prop="action"
                            [resizeable]="false" [flexGrow]="1"
                            [sortable]="false"
                            headerClass="tn_py_2 tn_text_center" cellClass="tn_py_2 center">
        <ng-template ngx-datatable-header-template>
          <strong> {{ dataTable.extra.actions.header ?? defaultColumnConfig.actions?.header }}</strong>
        </ng-template>
        <ng-template let-dataItem="row" ngx-datatable-cell-template>
        <span class="flex gap-1">
            @for (action of dataTable.extra.actions.data; track action) {
              <mkad-table-button
                [type]="action.type"
                [disabled]="action?.disable?.(dataItem) ?? false"
                [nbTooltip]="(getTooltipData(action.type)) | translate"
                [nbTooltipDisabled]="action?.disable?.(dataItem) ?? false"
                [nbTooltipPlacement]="'end'"
                (click)="rowClick.emit({type: action.type, row: dataItem})"/>
            }
          </span>
        </ng-template>
      </ngx-datatable-column>
    }
  </ngx-datatable>
} @else {
  <mkad-alert [message]="dataTable?.extra?.messages?.empty ?? ('app.shared.tables.message.noChildData' | translate)"
              status="warning"/>
}
