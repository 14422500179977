/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         12/03/24 - 14:29
 * Module name:  tag-component.constant
 * File name:    tag-component.constant.ts
 * IDE:          WebStorm
 */

export const SIDEBAR_MAIN_ID = 'SIDEBAR_MAIN_ID';
export const SIDEBAR_DEVICE_ID = 'SIDEBAR_DEVICE_ID';
export const MENU_MAIN_TAG = 'MENU_MAIN_TAG';
export const MENU_OVERLAY_TAG = 'MENU_OVERLAY_TAG';

