import {Component, Input, ViewEncapsulation} from '@angular/core';
import {
  DEFAULT_EXTRA_CONFIG,
  isViewPhone,
  isViewText,
  validStringType,
  TableColumnEnum,
  TableImageConfigInterface,
} from '@shared/components/table';

export type BasicRecordType = Record<string, unknown>;

@Component({
  selector: 'mkad-column-basic-view',
  standalone: true,
  imports: [],
  encapsulation: ViewEncapsulation.None,
  template: `
    @if (isViewPhone(type)) {
      <span>{{ phoneValue }}</span>
    }
    @if (validStringType(type)) {
      @if (isColumnText(type)) {
        <span>{{ value }}</span>
      }
    }
  `,
})
export class TableColumnBasicViewComponent {
  @Input({required: true}) type: TableColumnEnum = TableColumnEnum.text;
  @Input({required: true}) value: string | BasicRecordType = '';
  @Input({required: false}) config?: TableImageConfigInterface = <TableImageConfigInterface>DEFAULT_EXTRA_CONFIG.image;
  protected readonly validStringType = validStringType;
  protected readonly isColumnText = isViewText;
  protected readonly isViewPhone = isViewPhone;

  /**
   * @description
   * Method that returns the value of phone
   */
  get phoneValue() {
    return (<BasicRecordType>this.value)['dial_code'] + ' ' + (<BasicRecordType>this.value)['phone_number'];
  }
}
