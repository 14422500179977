import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  assertionColumnMode,
  assertionSortMode,
  assertionString,
  validArrayType,
  DEFAULT_EXTRA_CONFIG,
  DEFAULT_TABLE_CONFIG,
  TableConfigInterface,
  TableExtraConfigInterface,
  TableIconClickInterface,
  TableRowClickInterface,
  TableThemeEnum,
  TableActionEnum, TABLE_ACTION_TOOLTIP,
} from '../../utils/';
import {MkadAlertComponent} from '@shared/components/global/atoms/alert/mkad-alert.component';
import {MkadTableModule} from '../../mkad-table.module';

@Component({
  selector: 'mkad-smart-table',
  standalone: true,
  imports: [
    MkadAlertComponent,
    MkadTableModule,
  ],
  templateUrl: './mkad-smart-table.component.html',
})
export class MkadSmartTableComponent {
  @Input({required: true}) dataTable?: TableConfigInterface;
  @Input({required: false}) limit = DEFAULT_TABLE_CONFIG.limit;
  @Input({required: false}) theme: TableThemeEnum = TableThemeEnum.light;
  @Output() rowClick: EventEmitter<TableRowClickInterface<any>> = new EventEmitter();
  @Output() iconClick: EventEmitter<TableIconClickInterface> = new EventEmitter();
  readonly defaultConfig = DEFAULT_TABLE_CONFIG
  protected readonly tableActionEnum = TableActionEnum;
  protected readonly validArrayType = validArrayType;
  protected readonly assertionString = assertionString;
  protected readonly assertionColumnMode = assertionColumnMode;
  protected readonly assertionSortMode = assertionSortMode;

  /**
   * @description
   * Method that returns the dark theme
   */
  get isDark(): boolean {
    return this.theme === TableThemeEnum.dark;
  }

  /**
   * @description
   * Method that returns the light theme
   */
  get isLight(): boolean {
    return this.theme === TableThemeEnum.light;
  }

  /**
   * @description
   * Method that returns the table configuration by input
   */
  get columnConfig(): TableExtraConfigInterface {
    return (this.dataTable && this.dataTable.extra)
      ? this.dataTable.extra
      : this.defaultColumnConfig;
  }
  /**
   * @description
   * Method that returns the default table configuration
   */
  get defaultColumnConfig(): TableExtraConfigInterface {
    return DEFAULT_EXTRA_CONFIG;
  }

  getTooltipData(type: TableActionEnum): string {
    return TABLE_ACTION_TOOLTIP[type]
  }

}
