import {Component, Input} from '@angular/core';
import {TABLE_ICONS_MAP, TableIconEnum, parseString} from '@shared/components/table';
import {NbIconModule, NbTooltipModule} from '@nebular/theme';

@Component({
  selector: 'mkad-table-icon-group',
  standalone: true,
  imports: [NbIconModule, NbTooltipModule],
  template: `
    @if (value && fields && icons) {
      <section class="flex gap-2">
        @for (item of icons; track item; let index = $index) {
          <nb-icon [pack]="parseString(iconMap[item].pack)"
                   [icon]="parseString(iconMap[item].icon)"
                   [nbTooltip]="tooltipsList[index]"
                   [nbTooltipDisabled]="!value[fields[index]]"
                   [class]="'in__table ' + (value[fields[index]] ? '' : 'disable')"/>
        }
      </section>
    }
  `,
})
export class TableIconsGroupComponent {
  @Input({required: true}) value: Record<string, unknown> | null = null;
  @Input({required: true}) fields?: string[] = [];
  @Input({required: true}) icons?: TableIconEnum[] = [];
  @Input({required: false}) tooltipsList: string[] = [];
  protected readonly iconMap = TABLE_ICONS_MAP;
  protected readonly parseString = parseString;
}
