/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2023 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2023 - 2024. Todos los derechos reservados.
 */

/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         12/07/23 - 10:25 AM
 * Module name:  language-available.enums
 * File name:    language-available.enums.ts
 * IDE:          WebStorm
 */

/**
 * Enumerator of languages available
 */
export enum AllowedLanguagesEnum {
  en = 'en',
  es = 'es'
}
