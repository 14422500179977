import {Component, Input, ViewEncapsulation} from '@angular/core';
import {
  TableColumnBasicViewComponent,
  TableTagComponent,
  TableIconsGroupComponent,
  TableIconComponent,
  TableColumnInitialsViewComponent
} from '../../../table/components/atoms';
import {
  assertionString,
  isViewEnum,
  isViewIconDuo,
  isViewIconEnum,
  isViewIconGroup,
  isViewImage,
  isViewInitials,
  isViewPhone,
  isViewTag,
  isViewText,
  isViewTextDuo,
  isViewIcon,
  DEFAULT_EXTRA_CONFIG, TABLE_ICONS_MAP, parseString,
} from '../../utils/mkad-table.const';
import {
  TableColumnEnum,
  TableColumnInterface,
  TableExtraConfigInterface,
} from '../../utils/mkad-table.interface';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NbCardModule, NbIconModule, NbPopoverModule, NbTooltipModule, NbTrigger} from '@nebular/theme';
import {JsonPipe} from '@angular/common';

@Component({
  selector: 'mkad-smart-table-column',
  standalone: true,
  imports: [
    NgxDatatableModule,
    NbCardModule,
    NbPopoverModule,
    NbTooltipModule,
    TableColumnBasicViewComponent,
    TableTagComponent,
    TableIconsGroupComponent,
    TableIconComponent,
    TableColumnInitialsViewComponent,
    JsonPipe,
    NbIconModule,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './mkad-smart-table-column.component.html'
})
export class MkadSmartTableColumnComponent {
  @Input({required: true}) columnItem?: TableColumnInterface;
  @Input({required: true}) rowItem: any;
  @Input({required: true}) columnConfig?: TableExtraConfigInterface;
  protected readonly isColumnImage = isViewImage;
  protected readonly contentTypeColumnEnum = TableColumnEnum;
  protected readonly isColumnPhone = isViewPhone;
  protected readonly isColumnTag = isViewTag;
  protected readonly isColumnEnum = isViewEnum;
  protected readonly isColumnIconEnum = isViewIconEnum;
  protected readonly isColumnTextDuo = isViewTextDuo;
  // todo: pass icon params to array and object type
  protected readonly isViewIcon = isViewIcon;
  protected readonly isColumnIconGroup = isViewIconGroup;
  protected readonly isColumnText = isViewText;
  protected readonly isColumnIconDuo = isViewIconDuo;
  protected readonly isColumnInitials = isViewInitials;
  protected readonly assertionString = assertionString;
  protected readonly nbTrigger = NbTrigger;

  /**
   * @description
   * Method that returns the child fields
   */
  get defaultColumnConfig(): TableExtraConfigInterface {
    return DEFAULT_EXTRA_CONFIG;
  }

  protected readonly parseString = parseString;
  protected readonly iconsMap = TABLE_ICONS_MAP;
}
