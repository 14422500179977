import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TableInitialsInterface} from '@shared/components/table';

@Component({
  selector: 'mkad-column-initials-view',
  standalone: true,
  imports: [],
  encapsulation: ViewEncapsulation.None,
  template: `
    @if (value && value.initials.length > 0) {
      <div class="avatar__wrapper flex justify-content-center">
        @for (initial of value.initials; track initial) {
          <span class="circle">{{ initial }}</span>
        }
        @if ((value.total - 5) > 0) {
          <span class="circle total">{{ '+' + (value.total - 5) }}</span>
        }
      </div>
    }
  `,
})
export class TableColumnInitialsViewComponent {
  @Input({required: true}) value: TableInitialsInterface = {initials: [], total: 0};
}
