/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         10/04/24 - 12:44
 * Module name:  http-status-code.const
 * File name:    http-status-code.const.ts
 * IDE:          WebStorm
 */
export const HTTP_CODE_SUCCESS = 200;
export const HTTP_CODE_CREATED = 201;
