import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {
  assertionColumnMode,
  assertionSortMode,
  assertionString,
  validArrayType,
  DEFAULT_EXTRA_CONFIG,
  DEFAULT_TABLE_CONFIG,
  TABLE_COLUMN_ICON_WIDTH,
  TABLE_CHILDREN_ROW_OFFSET,
  TABLE_CHILDREN_ROW_HEIGHT,
  TABLE_CHILDREN_KEY, TABLE_ACTION_TOOLTIP,
} from '../../utils/mkad-table.const';
import {
  TableExtraConfigInterface,
  TableActionEnum,
  TableRowClickInterface,
  TableConfigInterface,
  TableIconClickInterface,
  TableThemeEnum,
} from '@shared/components/table';
import {MkadAlertComponent} from '@shared/components/global/atoms/alert/mkad-alert.component';
import {MkadSmartTableChildComponent} from '../mkad-smart-table-child/mkad-smart-table-child.component';
import {MkadTableModule} from '../../mkad-table.module';

@Component({
  selector: 'mkad-smart-table-nested',
  standalone: true,
  imports: [
    MkadSmartTableChildComponent,
    MkadAlertComponent,
    MkadTableModule,
  ],
  templateUrl: './mkad-smart-table-nested.component.html',
})
export class MkadSmartTableNestedComponent {
  @ViewChild('tableNested') table!: DatatableComponent;
  @Input({required: true}) dataTable?: TableConfigInterface;
  @Input({required: false}) limit = DEFAULT_TABLE_CONFIG.limit;
  @Input({required: false}) theme: TableThemeEnum = TableThemeEnum.light;

  @Output() rowClick: EventEmitter<TableRowClickInterface<any>> = new EventEmitter();
  @Output() iconClick: EventEmitter<TableIconClickInterface> = new EventEmitter();
  @Output() rowClickChildren: EventEmitter<TableRowClickInterface<any>> = new EventEmitter();
  @Output() iconClickChildren: EventEmitter<TableIconClickInterface> = new EventEmitter();
  protected readonly defaultConfig = DEFAULT_TABLE_CONFIG;
  protected readonly tableActionEnum = TableActionEnum;
  protected readonly validArrayType = validArrayType;
  protected readonly assertionString = assertionString;
  protected readonly assertionColumnMode = assertionColumnMode;
  protected readonly assertionSortMode = assertionSortMode;

  // collapse section
  tableChildCountOfRows = this.defaultConfig.limit;
  tableRowExpanded: any;
  // sizes
  iconColumnWidth = TABLE_COLUMN_ICON_WIDTH;
  childrenRowHeight = TABLE_CHILDREN_ROW_HEIGHT;
  childrenRowOff = TABLE_CHILDREN_ROW_OFFSET;

  get getChildrenField() {
    const data = this.dataTable;
    if (data && data.children && data.children.field) return data.children.field;
    return TABLE_CHILDREN_KEY;
  }

  /**
   * @description
   * Method that returns the child fields
   */
  get defaultColumnConfig(): TableExtraConfigInterface {
    return DEFAULT_EXTRA_CONFIG;
  }

  /**
   * @description
   * Method that returns the table configuration by input
   */
  get columnConfig(): TableExtraConfigInterface {
    return (this.dataTable && this.dataTable.extra)
      ? this.dataTable.extra
      : this.defaultColumnConfig;
  }

  /**
   * @description
   * Method that returns the configuration of the children table by entry
   */
  get childrenColumnConfig(): TableExtraConfigInterface {
    const data = this.dataTable;
    if (data && data.children && data.children.extra) return data.children.extra;
    return this.defaultColumnConfig;
  }

  /**
   * @description
   * Method that returns the theme of the table
   */
  get tableTheme(): Record<string, boolean> {
    return {
      'dark': this.theme === TableThemeEnum.dark,
      'bootstrap': this.theme === TableThemeEnum.light,
    };
  }

  /**
   * @description
   * Method that returns the height row
   */
  get heightDetailRow() {
    const zero = 0;
    return this.childrenRowOff + (this.tableChildCountOfRows === zero ? zero : (this.childrenRowHeight * this.calcRowCount));
  }

  /**
   * @description
   * Method that returns the calc row count
   */
  get calcRowCount() {
    const zero = 0;
    if (this.tableChildCountOfRows === zero) return zero;
    if (this.tableChildCountOfRows <= DEFAULT_TABLE_CONFIG.limit) return this.tableChildCountOfRows;
    return DEFAULT_TABLE_CONFIG.limit;
  }

  /**
   * @description
   * Method that handles row expansion
   * @param row
   */
  handlerExpandRow(row: any) {
    if (this.tableRowExpanded) {
      this.table.rowDetail.toggleExpandRow(this.tableRowExpanded);
    }
    if (this.tableRowExpanded !== row) {
      this.table.rowDetail.toggleExpandRow(row);
      this.tableRowExpanded = row;
    } else {
      this.tableRowExpanded = undefined;
    }
    this.tableChildCountOfRows = row[this.getChildrenField].length;
  }

  getTooltipData(type: TableActionEnum): string {
    return TABLE_ACTION_TOOLTIP[type]
  }
}
