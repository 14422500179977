/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         12/03/24 - 7:27
 * Module name:  time-control.constant
 * File name:    time-control.constant.ts
 * IDE:          WebStorm
 */

/**
 * @description
 * Used to set a timeout in milliseconds status device
 */
export const STATUS_DEVICE_TIMEOUT = 15000; // 15s
/**
 * @description
 * Used to set a timeout in milliseconds refresh token
 */
export const MAIN_TOKEN_REFRESH = 1800000; // 30m
