/**
 * Enumerator of available themes
 */
export enum MkadThemeValueEnum {
  corporate = 'corporate',
  dark = 'dark',
  cosmic = 'cosmic',
  materialLight = 'material-light',
  materialDark = 'material-dark'
}
