@if (columnItem) {
  @if (isColumnText(columnItem.type) || isColumnPhone(columnItem.type)) {
    <mkad-column-basic-view
      [type]="columnItem.type"
      [value]="rowItem[assertionString(columnItem.field)]"
      [config]="columnConfig?.image ?? defaultColumnConfig.image"/>
  }
  @if (isColumnTag(columnItem.type)) {
    <mkad-table-tag
      [value]="rowItem[assertionString(columnItem.field)]"
      [config]="columnConfig?.tag ?? defaultColumnConfig.tag"/>
  }
  @if (isViewIcon(columnItem.type)) {
    @if (columnItem.dualIcon && columnItem.dualIcon.length > 0) {
      <mkad-table-icon class="cursor-pointer"
        [nbTooltip]="rowItem[assertionString(columnItem.field)]"
        nbTooltipPlacement="left"
        [nbTooltipTrigger]="nbTrigger.CLICK"
        [nbTooltipDisabled]="!rowItem[assertionString(columnItem.field)]"
        [icon]="rowItem[assertionString(columnItem.field)] ? columnItem.dualIcon[0]: columnItem.dualIcon[1]"
        [class]="rowItem[assertionString(columnItem.field)] ? 'table__active': 'table__inactive'"/>
    } @else {
      <nb-icon
        [nbTooltip]="rowItem[assertionString(columnItem.field)]"
        nbTooltipPlacement="left"
        [nbTooltipTrigger]="nbTrigger.CLICK"
        [nbTooltipDisabled]="!rowItem[assertionString(columnItem.field)]"
        [pack]="'far'" [icon]="assertionString(rowItem[assertionString(columnItem.field)])"/>
    }
  }
  @if (isColumnIconGroup(columnItem.type)) {
    <mkad-table-icon-group
      [value]="rowItem"
      [icons]="columnItem.groupIcon"
      [fields]="columnItem.groupIconFields"
      [tooltipsList]="columnItem.groupIconTooltips ?? []"
    />
  }
  @if (isColumnImage(columnItem.type) && columnItem.dualIcon) {
    @if (rowItem[assertionString(columnItem.field)]) {
      <mkad-table-icon class="cursor-pointer table__active"
                       [icon]="columnItem.dualIcon[0]"
                       [nbPopover]="logoTemplate"
                       nbPopoverPlacement="left"
                       [nbPopoverTrigger]="'click'"/>
      <ng-template #logoTemplate>
        <nb-card class="p-2 m-0">
          <img [src]="rowItem[assertionString(columnItem.field)]" class="w-auto min__height__logo"
               alt="image-customer-logo" loading="lazy"/>
        </nb-card>
      </ng-template>
    } @else {
      <mkad-table-icon [icon]="columnItem.dualIcon[1]" class="table__inactive"/>
    }
  }
  @if (isColumnEnum(columnItem.type) && columnItem.enumList) {
    <mkad-column-basic-view [type]="contentTypeColumnEnum.text"
                            [value]="columnItem.enumList[rowItem[assertionString(columnItem.field)]]"/>
  }
  @if (isColumnTextDuo(columnItem.type)) {
    @for (dualField of columnItem.dualField; track dualField) {
      <mkad-column-basic-view
        [type]="contentTypeColumnEnum.text"
        [value]="rowItem[assertionString(dualField)]"/>
      {{ ' ' }}
    }
  }
  @if (isColumnIconEnum(columnItem.type) && columnItem.dualIcon && columnItem.enumIcon) {
    <mkad-table-icon [icon]="columnItem.dualIcon[0]"
                     [class]="columnItem.enumIcon[rowItem[assertionString(columnItem.field)]]"/>
  }
  @if (isColumnIconDuo(columnItem.type) && columnItem.dualIcon && columnItem.dualIcon.length > 0) {
    <mkad-table-icon
      [icon]="rowItem[assertionString(columnItem.field)] ? columnItem.dualIcon[0] : columnItem.dualIcon[1]"
      [class]="rowItem[assertionString(columnItem.field)] ? columnItem.dualIcon[0] : columnItem.dualIcon[1]"/>
  }
  @if (isColumnInitials(columnItem.type)) {
    <mkad-column-initials-view [value]="rowItem[assertionString(columnItem.field)]"/>
  }
}
