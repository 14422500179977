/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         23/02/24 - 13:23
 * Module name:  format-type-export.enum
 * File name:    format-type-export.enum.ts
 * IDE:          WebStorm
 */
/**
 * Enumerator of format type export  available
 */
export enum FormatTypeExportEnum {
  XLS,
  XLSX,
  CSV,
  TXT
}
