/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2023 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2023 - 2024. Todos los derechos reservados.
 */

/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         7/07/23 - 10:25 AM
 * Module name:  animation-properties.constants.ts
 * File name:    animation-properties.constants.ts
 * IDE:          WebStorm
 */

import {AnimationConfigInterface} from '../../interface';

/**
 * @description
 * configuration for a spinning animation
 */
export const ANIMATION_CONFIG: AnimationConfigInterface = {
  property: {
    optional: ['animation-name', 'animation-duration'],
    strict: ['animation-iteration-count', 'animation-timing-function', 'animation-direction']
  },
  value: ['fa-spin', 'var(--fa-animation-duration, 0.5s)', 'var(--fa-animation-iteration-count,infinite)', 'var(--fa-animation-timing,linear)', 'var(--fa-animation-direction,normal)']
};
