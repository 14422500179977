/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         21/02/24 - 1:50
 * Module name:  user-overlay-menu.constants
 * File name:    user-overlay-menu.constants.ts
 * IDE:          WebStorm
 */
import {NbMenuItem} from '@nebular/theme';
import {MenuComponentOverlayEnum} from '../../enums';

/**
 * @description
 * User menu option
 */
export const MENU_USER_OVERLAY: NbMenuItem[] = [
  {
    title: 'app.shared.userOverlay.profile',
    icon: {
      pack: 'far',
      icon: 'user-pen'
    },
    data: {
      component: MenuComponentOverlayEnum.UserProfile
    }
  }, {
    title: 'app.shared.userOverlay.app',
    icon: {
      pack: 'far',
      icon: 'gears'
    },
    data: {
      component: MenuComponentOverlayEnum.AppPreferences
    }
  }, {
    title: 'app.shared.userOverlay.changePassword',
    icon: {
      pack: 'far',
      icon: 'key'
    },
    data: {
      component: MenuComponentOverlayEnum.ChangePassword
    }
  }, {
    title: 'app.shared.userOverlay.logout',
    icon: {
      pack: 'far',
      icon: 'right-from-bracket'
    },
    data: {
      component: MenuComponentOverlayEnum.Logout
    }
  }
];

